/* eslint-disable @typescript-eslint/no-explicit-any */
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';

export function loggerCallback(logLevel: LogLevel, message: string) {
    switch (logLevel) {
      case LogLevel.Error:
        console.error(message);
        return;
      case LogLevel.Info:
        console.info(message);
        return;
      case LogLevel.Verbose:
        console.debug(message);
        return;
      case LogLevel.Warning:
        console.warn(message);
        return;
      default:
        console.log(message);
        return;
    }
  }
  
  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.azureAD.msalConfig.auth.clientId,
        authority: environment.azureAD.msalConfig.auth.authority,
        redirectUri: '/content/specifications',
        postLogoutRedirectUri: '/',
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: true,
        },
      },
    });
  }
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    environment.apiConfig.forEach((a) =>
      protectedResourceMap.set(a.uri, [
        ...(a.apiScopes
          ? a.apiScopes
              .filter((value, index, array) => array.indexOf(value) === index)
              .filter((a) => a.length > 0)
          : []),
        ...(a.graphScopes
          ? a.graphScopes
              .filter((value, index, array) => array.indexOf(value) === index)
              .filter((a) => a.length > 0)
          : []),
      ]),
    );

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [
          ...environment.apiConfig
            .map((a: any) => a.apiScopes?.join(','))
            .filter((value: any, index: any, array: any) => array.indexOf(value) === index)
            .filter((a: any) => a.length > 0),
        ],
  
        extraScopesToConsent: [
          ...environment.apiConfig
            .map((a: any) => a.apiScopes?.join(','))
            .filter((value: any, index: any, array: any) => array.indexOf(value) === index)
            .filter((a: any) => a.length > 0),
        ],
      },
      loginFailedRoute: '/login-failed',
    };
  }
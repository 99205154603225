import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { defineCustomElements } from '@scania/tegel/loader';
import { importProvidersFrom } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app/app.routes';
import { ToastrModule } from 'ngx-toastr';
import { ErrorToastComponent } from './app/common/error-toast/error-toast.component';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { provideMsal } from './app/core/auth/msal-providers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { appLayoutReducer, } from './app/common/store/app-layout.reducer';
import { AppLayoutToastEffects } from './app/common/store/app-layout.effects';
import { SpecificationStructureEffects } from './app/specification/store/effects';


defineCustomElements(window);

bootstrapApplication(AppComponent,
  {
    providers: [
      provideMsal(),
      importProvidersFrom(
        HttpClient,
        CommonModule,
        RouterModule.forRoot(routes, {
          scrollPositionRestoration: "enabled",
        }),

        ToastrModule.forRoot({
          toastComponent: ErrorToastComponent,
          disableTimeOut: false,
          closeButton: true,
          timeOut: 5000,
          extendedTimeOut: 4000,
          positionClass: 'toast-top-right',
        }),
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        // StoreModule.forFeature(appLayoutFeatureKey, appLayoutReducer),
        // EffectsModule.forFeature([AppLayoutToastEffects]),
        // StoreModule.forRoot( {appLayout:appLayoutReducer }  ),
        // EffectsModule.forRoot([AppLayoutToastEffects]),
      ),

       provideStore(
                    {appLayout:appLayoutReducer},
                    //  { 
                    //   metaReducers: rootMetaReducers,
                    //   initialState: rehydrateState() 
                    // }
                  ),
       provideEffects(AppLayoutToastEffects,SpecificationStructureEffects), 

      provideStoreDevtools({ maxAge: 25, logOnly: false }), //Retains last 25 states, // Restrict extension to log-only mode
    ],
  }).catch((err) => console.error(err));

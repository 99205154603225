import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular-17';

@Component({
  selector: 'app-unauthorised',
  standalone: true,
  imports: [TegelModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent {

}

import { Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { RoleGuard } from './core/guards/role-guard';
import { LoginFailedComponent } from './login-failed/login-failed.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';

export const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [MsalGuard],
        canActivateChild: [MsalGuard],
        children: [
          {
            path: 'rbacc',
            loadChildren: () => import('../app/rbacc/rbacc-routing.module').then(m => m.RbaccRoutingModule),
            canActivateChild: [RoleGuard.canLoad],
            data: {
              requiredRoles: [],
            },
          },
          {
            path: 'content/specifications',
            loadChildren: () => import('../app/specification/specification-routing.module').then(m => m.SpecificationStructureRoutingModule),
            canActivateChild: [RoleGuard.canLoad],
            data: {
              requiredRoles: [],
            },
          },
          {
            path: 'content/vsoc',
            loadChildren: () => import('../app/vsoc/vsoc-routing.module').then(m => m.VsocRoutingModule),
            canActivate: [RoleGuard.canLoad],
            data: {
              requiredRoles: [],
            },
          },
          {
            path: 'export',
            loadChildren: () => import('../app/export/export-routing.module').then(m => m.ExportRoutingModule),
            canActivateChild: [RoleGuard.canLoad],
            data: {
              requiredRoles: [],
            },
          },

          {
            path: 'no-access',
            component: UnauthorisedComponent,
          },
          {
            path: 'login-failed',
            component: LoginFailedComponent,
          },
          {
            path: '',
            redirectTo: 'content/specifications',
            pathMatch: 'full',
          },
          {
            path: 'not-found',
            children: [
              {
                path: '',
                component: NotFoundComponent,
              },
            ],
          },
          {
            // Dedicated route for redirects
            path: 'auth',
            component: MsalRedirectComponent,
          },
          {
            path: '**',
            redirectTo: 'not-found',
            pathMatch: 'full',
          },
        ],
      },
      // {
      //     path: 'rbacc',
      //     component: RbaccComponent,
      // }
];

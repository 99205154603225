import { Component, OnDestroy } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular-17';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppLayoutState } from '../store/app-layout.reducer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [TegelModule, CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  isLoading: Observable<boolean>;

  constructor(private store: Store<{ appLayout: AppLayoutState }>) {
    this.isLoading = this.store.select(state => state.appLayout.isLoading ).pipe(takeUntil(this.onDestroy$));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  UrlTree,
  Route,
  Router,
  CanActivateChildFn,
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { MsalService } from '@azure/msal-angular';
import { Roles, RolePriority } from '../../common/constants';

export const RoleGuard: {
  canActivate: CanActivateFn;
  canActivateChild: CanActivateChildFn;
  canLoad: CanMatchFn;
  canReleaseVersion: CanMatchFn
} = {
  canActivate: (
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    return checkAccess(route.data?.['requiredRoles']);
  },

  canActivateChild: (
    childRoute: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    return checkAccess(childRoute.data?.['requiredRoles']);
  },

  canLoad: (
    route: Route
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    return checkAccess(route.data?.['requiredRoles']);
  },

  // Functions to check specific roles

  // canEditIdentificationsGroup(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN]);
  // },

  // canEditDignosticFamilies(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN]);
  // },

  // canEditUsers(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN]);
  // },

  // canCreateExportLabel(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN]);
  // },

  // canManageTemplate(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN]);
  // },

  // canApproveRbacc(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN]);
  // },

  canReleaseVersion(): Observable<boolean | UrlTree> {
    return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  },

  // canCreateDeleteSpecification(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  // },

  // canEditSharedFiles(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  // },

  // canEditRbacc(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  // },

  // canEditSCOMMFiles(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  // },

  // canEditServer(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  // },

  // canEditDiagnosticService(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  // },

  // canEditLegacyFile(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER]);
  // },

  // canEditCategoryItems(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER, Roles.COLLABORATOR]);
  // },

  // CanEditSpecificationVersion(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER, Roles.COLLABORATOR]);
  // },

  // canAddVersions(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER, Roles.COLLABORATOR]);
  // },

  // canValidateServerFile(): Observable<boolean | UrlTree> {
  //   return checkAccess([Roles.ADMIN, Roles.SYSTEMOWNER, Roles.COLLABORATOR]);
  // },

  // canEditExportPackage(): Observable<boolean | UrlTree> {
  //   return checkAccess([
  //     Roles.ADMIN,
  //     Roles.SYSTEMOWNER,
  //     Roles.COLLABORATOR,
  //     Roles.READER,
  //   ]);
  // },

  // canExportByLabel(): Observable<boolean | UrlTree> {
  //   return checkAccess([
  //     Roles.ADMIN,
  //     Roles.SYSTEMOWNER,
  //     Roles.COLLABORATOR,
  //     Roles.READER,
  //   ]);
  // },
};

function checkAccess(
  requiredRoles: string[] = []
): Observable<boolean | UrlTree> {
  const router = inject(Router);
  const msalService = inject(MsalService);
  const userService = inject(UserService);
  const userRoles = msalService.instance.getAllAccounts()[0]?.idTokenClaims?.roles;

  //if user has no role in token , return unauthorised
  //or if it has any role , set the user role
  const currentUserRole =
    !userRoles || userRoles.length === 0
      ? (router.navigate(['/no-access']), '')
      : userService.getUserRole();

      //if user has no required role,by default still allow access
  if (requiredRoles.length === 0 ||(requiredRoles.length === 1 && requiredRoles[0] === '')) {
    // No specific roles required, allow access
    return of(true);
  }

  //if user is admin, he should be able to access modules/compo lower to roles in hierarchy
  //but if user is reader role-guard will block the user from accessing admin module/compo
  // Assign numerical values to roles based on hierarchy
  const rolePriority: { [key: string]: number } = RolePriority;
  const roles = currentUserRole.toLowerCase().split(',');
  let userHighestRole = '';
  userHighestRole = roles.reduce((highestRole, currentRole) => {
    return rolePriority[currentRole] > rolePriority[highestRole]
      ? currentRole
      : highestRole;
  });

  // Get the highest hierarchy level of required roles
  const requiredHighestRole = requiredRoles.reduce(
    (highestRole, currentRole) => {
      const currentRolePriority = RolePriority[currentRole.toLowerCase()] || 0;
      const highestRolePriority = RolePriority[highestRole.toLowerCase()] || 0;
      return currentRolePriority > highestRolePriority
        ? currentRole.toLowerCase()
        : highestRole.toLowerCase();
    },
    ''
  );

  // Check if the user has the required role or a higher role in the hierarchy
  if (rolePriority[userHighestRole] >= rolePriority[requiredHighestRole]) {
    // User has the required role or a higher role, allow access
    return of(true);
  } else {
    // User doesn't have the required role or a higher role, navigate to no-access page
    return of(router.createUrlTree(['/no-access']));
  }
}


<tds-footer>
    <div slot="start">
      <tds-footer-group>
        <tds-footer-item>
          <a href="#">Release Notes</a>
        </tds-footer-item>
        <tds-footer-item>
          <a href="#">FAQ</a>
        </tds-footer-item>
        <tds-footer-item>
          <a href="#">Support </a>
        </tds-footer-item>
        <tds-footer-item>
          <a href="#">Wiki</a>
        </tds-footer-item>
      </tds-footer-group>
    </div>
  </tds-footer>
  
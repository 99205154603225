import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppLayoutActions } from './app-layout.actions';

@Injectable()
export class AppLayoutToastEffects {

  showToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AppLayoutActions.showToast,
          AppLayoutActions.showSuccessToast,
          AppLayoutActions.showInfoToast,
          AppLayoutActions.showWarningToast,
          AppLayoutActions.showErrorToast,
        ),
        tap(({ title, message, type }) => {
          switch (type) {
            case '[Toastr] Show Success Toast':
              this.toastrService.success(message, title,  {
                toastClass: 'success',
              } );
              break;
            case '[Toastr] Show Info Toast':
              this.toastrService.info(message, title, {
                toastClass: 'information',
              } );
              break;
            case '[Toastr] Show Warning Toast':
              this.toastrService.warning(message, title, {
                toastClass: 'warning',
              });
              break;
            case '[Toastr] Show Error Toast':
              this.toastrService.error(message, title, {
                toastClass: 'error',
              } );
              break;
            default:
              this.toastrService.show(message, title,  );
              break;
          }
        })
      );
    },
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private readonly toastrService: ToastrService
  ) {}
}


import { Component } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular-17';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-error-toast',
  standalone: true,
  imports: [TegelModule],
  templateUrl: './error-toast.component.html',
  styleUrls: ['./error-toast.component.scss']
})
export class ErrorToastComponent extends Toast{
   // used for demo purposes
   undoString = 'undo';

   // constructor is only necessary when not using AoT
   constructor(
     protected override toastrService: ToastrService,
     public override toastPackage: ToastPackage,
   ) {
     super(toastrService, toastPackage);
   }

}

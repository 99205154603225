
<div class="error-page tds-u-h-100">
    <div class="error-page__wrapper">
      <tds-icon
        class="error-page__icon"
        name="link_broken"
        size="24"></tds-icon>
      <div class="error-page__info">
        <h2 class="tds-headline-04">Access denied</h2>
        <p class="tds-body-01">
            You're not authorized as you do not have any role mapped for Dcode OR
             you do not have required role to access the page.
            Kindly raise role access on 
<a class="visited:text-orange-700" href="https://iga.scania.com/identityiq/home.jsf">
   IGA  
  </a>

        </p>
      </div>
    </div>
  </div>
  

import { CUSTOM_ELEMENTS_SCHEMA, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../../core/services/user.service';
import { Constants } from '../../common/constants';
import { UserProfile } from '../../core/models/user.model';
import { TegelModule } from '@scania/tegel-angular-17';
import { Router, RouterModule } from '@angular/router';

import { environment } from '../../../environments/environment';
import { ErrorService } from '../../core/services/error.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TegelModule, RouterModule],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  activeHeaderItem = '';
  loginDisplay = false;
  AppHeader = Constants;
  // profilePic$: Observable<any> = new Observable();
  profilePic?: SafeResourceUrl;
  public default: SafeResourceUrl = 'https://www.svgrepo.com/show/170303/avatar.svg';
  _destroying$ = new Subject<void>();
  profile: UserProfile = new UserProfile();
  expandedItem = '';
  currentUserRole = '';
  constructor(
    private readonly msalService: MsalService,
    private readonly domSanitizer: DomSanitizer,
    public readonly userService: UserService,
    public router: Router,
    private errorService: ErrorService
  ) {}
  isDropdownOpen = false;

  ngOnInit(): void {
    this.setLoginDisplay()
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    this.getUserRole();
    this.getProfile();
    this.getProfilePicture();
  }

  getUserRole(){
    const userClaims = this.msalService.instance.getAllAccounts()[0]?.idTokenClaims;
    const userRoles = userClaims?.roles;
    this.currentUserRole = (!userRoles ||  userRoles.length === 0) 
                                ? (this.router.navigate(['/no-access']), "")
                                : this.userService.getUserRole()
                                
     }

  getProfile() {
    this.userService
      .getProfile()
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (profile) => (this.profile = profile),
        error: () => this.errorService.error('Error fetching user profile'),
      });
  }

  getProfilePicture() {
    this.userService
      .getProfilePicture()
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (profilePic) => {
          if (profilePic) {
            const url = window.URL || window.webkitURL;
            this.profilePic = this.domSanitizer.bypassSecurityTrustResourceUrl(
              url.createObjectURL(profilePic)
            );
          }
        },
      });
  }

  onLogout() {
    sessionStorage.clear();
    this.msalService.logoutRedirect();
  }

  diagnosticSpeci(){
    window.location.href = environment.diagSpecifi + 'content/specifications';
  }

  diagnosticFamillies(){
    window.location.href = environment.diagSpecifi + 'content/diagnostic-families/0';
  }

  identficationGroup(){
    window.location.href = environment.diagSpecifi + 'content/identification-groups/0';
  }

  sharedFiles(){
    window.location.href = environment.diagSpecifi + 'content/shared-files';
  }

  packages(){
    window.location.href = environment.diagSpecifi + 'release/view/';
  }
  
  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
    this._destroying$.unsubscribe();
  }
}

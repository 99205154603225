<tds-header>
  <tds-header-hamburger id="demo-hamburger" aria-label="Open application drawer" aria-haspopup="true"
    aria-expanded="false">
  </tds-header-hamburger>

  <tds-header-title>{{AppHeader.appTitle}}</tds-header-title>
  <tds-header-dropdown>
    <span slot="label">{{AppHeader.content}}</span>
    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-item>
        <a (click)="diagnosticSpeci()" (keyup)="diagnosticSpeci()" tabindex="0">
          {{AppHeader.diagnosticSpecificatiosn}}
        </a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a (click)="diagnosticFamillies()" (keyup)="diagnosticFamillies()" tabindex="1">
          {{AppHeader.diagnosticFamilies}}
        </a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a (click)="identficationGroup()" (keyup)="identficationGroup()" tabindex="2">
          {{AppHeader.identificationGroups}}
        </a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a (click)="sharedFiles()" (keyup)="sharedFiles()" tabindex="3">
          {{AppHeader.sharedFiles}}
        </a>
      </tds-header-dropdown-list-item>
      <!-- <tds-header-dropdown-list-item
      [attr.selected]="activeHeaderItem === 'vsoc' ? '' : null"
      (click)="activeHeaderItem = 'vsoc'"
      ><a routerLink="/content/vsoc">{{AppHeader.vsoc}}</a></tds-header-dropdown-list-item> -->
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-dropdown>
    <span slot="label">{{AppHeader.export}}</span>
    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-item>
        <a (click)="packages()" (keyup)="packages()" tabindex="4"> {{AppHeader.packages}} </a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item [attr.selected]="activeHeaderItem === 'label' ? '' : null" (click)="activeHeaderItem = 'label'">
        <a routerLink="/export/label"> {{AppHeader.label}} </a> 
        <!-- <tds-header-dropdown-list-item>
        <a (click)="label()" (keyup)="label()" tabindex="5"> {{AppHeader.label}} </a> -->
      </tds-header-dropdown-list-item>
      
      <tds-header-dropdown-list-item [attr.selected]="activeHeaderItem === 'specifications' ? '' : null" (click)="activeHeaderItem = 'specifications'">
        <a routerLink="/export/specifications"> {{AppHeader.specificationslabel}} </a> 
      </tds-header-dropdown-list-item>

    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-item [attr.selected]="activeHeaderItem === 'rbacc' ? '' : null" (click)="activeHeaderItem = 'rbacc'">
    <a routerLink="/rbacc">{{AppHeader.rbaccHeading}}</a>
  </tds-header-item>

  @if (currentUserRole) {
    <tds-header-title class="tds-body-02" slot="end" placement="end">{{currentUserRole}}</tds-header-title>
  }

  <tds-header-dropdown slot="end" placement="end" no-dropdown-icon class="demo-hide demo-lg-show">
    <img slot="icon" [src]="(loginDisplay && profilePic) ? profilePic: default" alt="User menu." />
    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-user header="{{ profile.displayName }}" subheader="{{ profile.jobTitle }}"> </tds-header-dropdown-list-user>
      <tds-header-dropdown-list-item>
        <a role="none" (click)="onLogout()">{{AppHeader.logout}}</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-brand-symbol slot="end">
    <a aria-label="Scania - red gryphon on blue shield" routerLink="/"></a>
  </tds-header-brand-symbol>
</tds-header>
import { Action, createReducer, on } from '@ngrx/store';
import { AppLayoutActions } from './app-layout.actions';
import { ToastrAction } from './app-layout.actions'

export interface AppLayoutState {
  toasts: ToastrAction[];
  isLoading: boolean
}

export const initialState: AppLayoutState = {
  toasts: [],
  isLoading: false
};
export const appLayoutReducer = createReducer(
  initialState,
  on(AppLayoutActions.loader, (state, {isLoading }) => ({
    ...state,
    isLoading: isLoading
  })),

  on(AppLayoutActions.showToast, (state, { title, message }) => ({
    ...state,
    toasts: [...state.toasts, { title, message }]
  })),
  on(AppLayoutActions.showSuccessToast, (state, { title, message }) => ({
    ...state,
    toasts: [...state.toasts, { title, message }]
  })),
  on(AppLayoutActions.showInfoToast, (state, { title, message }) => ({
    ...state,
    toasts: [...state.toasts, { title, message }]
  })),
  on(AppLayoutActions.showWarningToast, (state, { title, message }) => ({
    ...state,
    toasts: [...state.toasts, { title, message }]
  })),
  on(AppLayoutActions.showErrorToast, (state, { title, message }) => ({
    ...state,
    toasts: [...state.toasts, { title, message }]
  }))
 
);

export function reducer(state: AppLayoutState | undefined, action: Action) {
  return appLayoutReducer(state, action);
}
// export const toastrFeature = createFeature({
//   name: 'toastrFeature',
//   reducer: appLayoutReducer,
//   // extraSelectors:() => ({}) => ({
//   //   selectRbaccByGuid = createSelector(
//   //     selectRbacId,
//   //     selectRbaccList,
//   //     (selectedId,rbaccList ) =>  rbaccList.find((rbacc) => rbacc.rbacGuid == selectedId)
//   //   )
//   // })
// })
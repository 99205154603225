import { Component } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../core/services/user.service';
import { TegelModule } from '@scania/tegel-angular-17';
import { HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from '../common/loader/loader.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports : [HeaderComponent,
             LoaderComponent, 
             RouterOutlet,
             TegelModule, 
             FooterComponent, 
             HttpClientModule
            ],
  providers:[MsalService, UserService],
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent  {}
